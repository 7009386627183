import vrb from 'bsm-validation-rules';

export const rules = {
  id: [
    vrb.presence(),
    vrb.typeOf('string'),
    vrb.length({ max: 30 }),
    vrb.format(/^([a-z0-9]+-?[a-z0-9]+)$/, 'must have valid ID format'),
  ],
  status: [vrb.presence(), vrb.typeOf('string')],
};
