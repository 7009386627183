<template>
  <v-autocomplete
    ref="selector"
    :value="value"
    :items="items"
    :search-input.sync="search"
    :loading="loading"
    :rules="validations.presence"
    :disabled="disabled"
    label="Client ID/Sub-brand"
    hint="Client ID/Sub-brand"
    item-text="label"
    item-value="id"
    multi-line
    clearable
    cache-items
    @input="$emit('input', $event)"
  />
</template>

<script>
import HTTP from '@/api/bee';
import { debounce } from 'lodash';
import validations from '@/helpers/validations';

export function ddrsEntity(x) {
  let label = `${x.name} (${x.client_id} / ${x.vc_id})`;
  if (x.parent) label = `${x.parent} / ${label}`;
  return { label };
}

export function fetchDDRSEntities() {
  this.loading = true;
  HTTP.get('/ddrs_entities', { params: { search: this.search, per_page: 50 } })
    .then(res => {
      this.items = res.data;
      this.items.forEach(x => {
        Object.assign(x, ddrsEntity(x));
      });
    })
    .finally(() => {
      this.loading = false;
    });
}

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: null,
    loading: false,
    validations,
    items: [],
    search: '',
  }),
  watch: {
    search: debounce(fetchDDRSEntities, 500),
    value(cur) {
      if (!cur) return;
      if (this.item === cur) return;
      this.item = cur;
      this.loading = true;
      HTTP.get(`/ddrs_entities/${cur}`)
        .then(res => {
          this.items = [res.data];
          this.items.forEach(x => {
            Object.assign(x, ddrsEntity(x));
          });
          this.ddrs = cur;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
