<template>
  <nv-page header="Unknown V60 Clients">
    <template slot="toolbar">
      <v-spacer class="hidden-sm-and-down" />
      <nv-table-search ref="search" v-model="search" class="mr-0 hidden-xs-only" />
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item.created_at="{ item }">
        <div :title="item.created_at | time('long')">
          {{ item.created_at | time }}
        </div>
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import HTTP from '@/api/bee';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';

const headers = [
  { text: 'V60 ID', sortable: false, value: 'v60_client' },
  { text: 'URL', sortable: false, value: 'url' },
  { text: 'Date Time', sortable: true, value: 'created_at' },
];

export default {
  mixins: [TableMixin],
  data: () => ({
    headers,
    tableFilters: { error_type: 'unknown_client' },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/mapping_errors');
    },
  },
};
</script>
