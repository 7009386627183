<template>
  <nv-page header="DCR Clients List">
    <template slot="toolbar">
      <v-spacer class="hidden-sm-and-down" />
      <nv-table-search ref="search" v-model="search" class="mr-0 hidden-xs-only" />
    </template>

    <template slot="filters">
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item.name="{ item }">
        <router-link :to="clientPath(item.id)">
          {{ item.name }}
        </router-link>
      </template>
      <template #item.status="{ item }">
        {{ item.status | humanize }}
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import HTTP from '@/api/bee';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import FilterSheet from '@/components/ddrs_entities/FilterSheet.vue';

const headers = [
  { text: 'Name', align: 'left', sortable: false, value: 'name' },
  { text: 'Parent', align: 'left', sortable: false, value: 'parent' },
  { text: 'Client ID', align: 'left', sortable: false, value: 'client_id' },
  { text: 'Sub brand', align: 'left', sortable: false, value: 'vc_id' },
  { text: 'Status', align: 'left', sortable: false, value: 'status' },
];

export default {
  components: {
    FilterSheet,
  },
  mixins: [TableMixin],
  data: () => ({
    headers,
    table: { sortBy: ['name'], sortDesc: [false] },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/ddrs_entities');
    },
    clientPath(ddrsID) {
      return { name: 'ddrs_entities.show', params: { ddrsID } };
    },
  },
};
</script>
