export function presence(name = '') {
  return v => !!v || `${name} must be present`;
}

export default {
  presence: [presence()],
  basic: [presence(), v => (v && v.length <= 100) || 'Must be less than 100 characters'],
  dcr: [
    presence('dcr'),
    v => (v && v.length <= 9) || 'Must be less than 10 characters',
    v => (v && /[a-z]{2}-[0-9]+/.test(v)) || 'Must be valid format',
  ],
  c6: [
    presence('c6'),
    v => (v && v.length <= 3) || 'Must be less than 4 characters',
    v => (v && /(b|c)[0-9]{2}/.test(v)) || 'Must be valid format',
  ],
};
