<template>
  <nv-app
    :navigation="navigation"
    title="BEE"
    :user="{ can: $auth.$auth.hasPermissions }"
    icon="/images/logo.png"
  />
</template>

<script>
const navigation = [
  { heading: 'Navigation' },
  { icon: 'mdi-format-list-bulleted', text: 'V60 Clients', route: '/v60_clients' },
  { icon: 'mdi-format-line-weight', text: 'DCR Clients', route: '/ddrs_entities' },
  { icon: 'mdi-format-list-bulleted', text: 'Unknown V60 Clients', route: '/unknown_v60_clients' },
  { icon: 'mdi-link', text: 'Rules', route: '/rules' },
];

export default {
  name: 'App',
  data: () => ({
    navigation,
  }),
};
</script>
