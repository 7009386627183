<template>
  <v-col cols="auto">
    <span class="text-caption mr-2">Status:</span>
    <v-btn-toggle v-model="filters.status" data-cy="status-filter-buttons">
      <v-btn v-for="item in statuses" :key="item.value" :value="item.value" small text>
        {{ item.text }}
      </v-btn>
    </v-btn-toggle>
  </v-col>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';

export default {
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'success',
    },
    statuses: [
      { text: 'Success', value: 'success' },
      { text: 'Error', value: 'error' },
    ],
    filtersParam: 'eval.ft',
  }),
};
</script>
