<template>
  <nv-page header="V60 Clients List">
    <template slot="toolbar">
      <v-spacer class="hidden-sm-and-up" />
      <nv-btn v-if="$auth.hasPermissions('bee.clients')" type="add" @click="form.show = true" />

      <edit-form v-model="form.show" @saved="tableFetch" />
      <v-spacer class="hidden-sm-and-down" />

      <nv-table-search ref="search" v-model="search" class="ml-4 mr-0 hidden-xs-only" />
    </template>

    <template slot="filters">
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="clientPath(item.id)">
              {{ item.id }}
            </router-link>
          </td>
          <td>{{ item.status | humanize }}</td>
        </tr>
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import HTTP from '@/api/bee';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import FilterSheet from '@/components/v60_clients/FilterSheet.vue';
import EditForm from '@/components/v60_clients/EditForm.vue';

const headers = [
  { text: 'V60 ID', align: 'left', sortable: false, value: 'id' },
  { text: 'Status', align: 'left', sortable: false, value: 'status' },
];

export default {
  components: {
    FilterSheet,
    EditForm,
  },
  mixins: [TableMixin],
  data: () => ({
    headers,
    form: { show: false },
    dialog: false,
    table: { sortBy: ['id'], sortDesc: [false] },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/v60_clients');
    },
    clientPath(v60ID) {
      return { name: 'v60_clients.show', params: { v60ID } };
    },
  },
};
</script>
