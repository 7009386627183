<template>
  <nv-tab-page>
    <template slot="toolbar">
      <nv-table-search ref="search" v-model="search" />
    </template>
    <v-container id="unmapped-table">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="table"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :items-per-page.sync="table.itemsPerPage"
        class="elevation-1"
      />
    </v-container>
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/bee';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';

const headers = [{ text: 'URL', align: 'left', sortable: false, value: 'url' }];

export default {
  mixins: [TableMixin],
  props: {
    v60: { type: String, default: null },
  },
  data: () => ({
    headers,
    table: { sortBy: ['created_at'], sortDesc: [true] },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/v60_clients/${this.v60}/mapping_errors`);
    },
  },
};
</script>
