<template>
  <div>
    <span v-if="!editMode">{{ value | humanize }}</span>
    <v-switch
      v-else
      :input-value="value"
      :loading="loading"
      :true-value="trueValue"
      :false-value="falseValue"
      hide-details
      color="accent"
      @change="update"
    />
  </div>
</template>

<script>
import HTTP from '@/api/bee';

export default {
  props: {
    value: { type: String, required: true },
    updateUrl: { type: String, required: true },
    editMode: { type: Boolean, default: false },
  },
  data: () => ({
    trueValue: 'active',
    falseValue: 'inactive',
    loading: false,
  }),
  methods: {
    update(status) {
      this.$emit('input', status);
      this.loading = true;
      HTTP.put(this.updateUrl, { status: status })
        .then(() => {
          this.$flash('success', 'Status updated');
        })
        .catch(() => {
          const rev = status === this.trueValue ? this.falseValue : this.trueValue;
          this.$emit('input', rev);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
