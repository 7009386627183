<template>
  <v-dialog v-model="mapDialog" persistent scrollable max-width="700px">
    <template #activator="{ on }">
      <nv-btn v-if="$auth.hasPermissions('bee.mappings')" ref="add-rule-btn" type="add" v-on="on" />
    </template>
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :loading="loading"
      :save-disabled="submittedItems.length > 9"
      title="New Mapping"
      save-text="Add Rule"
      @cancel="close"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col v-if="!v60" cols="12">
          <v60-client-autocomplete
            v-model="v60Id"
            data-cy="v60"
            :disabled="!!submittedItems.length"
          />
        </v-col>

        <v-col v-if="!ddrs" cols="12">
          <ddrs-entity-autocomplete
            v-model="ddrsEntityId"
            data-cy="ddrs"
            :disabled="!!submittedItems.length"
          />
        </v-col>

        <v-col v-if="submittedItems.length" cols="12">
          <v-subheader class="ml-0">Saved Rules</v-subheader>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">Rule</th>
                  <th class="text-left">Match Subdomains</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="it in submittedItems" :key="it.pattern">
                  <td>{{ it.pattern }}</td>
                  <td>{{ it.match_subdomains | formatMatch }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" sm="7">
          <v-text-field
            ref="pattern"
            v-model="item.pattern"
            :rules="rules.pattern"
            validate-on-blur
            data-cy="pattern"
            label="Pattern"
            hint="Pattern to match URL"
            counter="100"
            required
          />
        </v-col>
        <v-col cols="12" sm="4" offset-md="1">
          <v-switch
            ref="match-subdomains"
            v-model="item.match_subdomains"
            :rules="rules.matchSubdomains"
            label="Match Subdomains"
            color="accent"
            required
            multi-line
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/bee';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import V60ClientAutocomplete from '@/components/v60_clients/Autocomplete.vue';
import DdrsEntityAutocomplete from '@/components/ddrs_entities/Autocomplete.vue';
import { rules } from '@/components/rules/validations';
import { formatMatch } from '@/helpers/filters';

export default {
  components: { DdrsEntityAutocomplete, V60ClientAutocomplete },
  filters: { formatMatch },
  mixins: [FormMixin],
  props: {
    v60: { type: String, default: null },
    ddrs: { type: [String, Number], default: null },
    defaultV60Client: { type: String, default: null },
  },
  data: () => ({
    mapDialog: false,
    v60Id: null,
    ddrsEntityId: null,
    rules,
    submittedItems: [],
  }),
  watch: {
    mapDialog(on) {
      if (on) this.formReset();
    },
  },
  methods: {
    formInit() {
      const init = { pattern: '', match_subdomains: true };
      if (this.defaultV60Client) {
        init.v60 = this.defaultV60Client;
      }
      if (this.v60) {
        init.v60 = this.v60;
      }
      if (this.ddrs) {
        init.ddrs_entity_id = this.ddrs;
      }
      return init;
    },
    formSave() {
      if (this.v60Id) {
        this.item.v60 = this.v60Id;
      } else if (this.ddrsEntityId) {
        this.item.ddrs_entity_id = this.ddrsEntityId;
      }
      const url = `/v60_clients/${this.item.v60}/rules`;
      return this.submitForm(HTTP, url, {
        success: ({ data }) => {
          this.$flash('success', 'Client Mapping created!');
          this.submittedItems.push({
            pattern: data.pattern,
            match_subdomains: data.match_subdomains,
          });
          if (this.submittedItems.length < 10) {
            this.$refs.pattern.focus();
          }
        },
      });
    },
    close(form) {
      this.mapDialog = false;
      this.formCancel(form);
      this.submittedItems = [];
      this.$emit('close');
    },
    formCancel(form) {
      this.formReset();
      this.v60Id = null;
      this.ddrsEntityId = null;
      this.formErrors = {};
      const elem = form || this.formElement();
      if (elem != null) {
        elem.resetValidation();
      }
    },
  },
};
</script>
