<template>
  <v-btn v-if="$auth.hasPermissions('bee.mappings')" :color="btnColor" @click="btnClick">
    <v-icon class="mr-1">mdi-pencil</v-icon>
    {{ btnText }}
  </v-btn>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
  },
  computed: {
    btnColor() {
      return this.value ? 'secondary' : 'default';
    },
    btnText() {
      return this.value ? 'Finish Editing Statuses' : 'Edit Statuses';
    },
  },
  methods: {
    btnClick() {
      this.$emit('input', !this.value);
    },
  },
};
</script>
