<template>
  <v-container>
    <v-row dense justify="center">
      <v-col class="text-center" cols="12" sm="4">
        <span class="text-caption mr-1">Status:</span>&nbsp;
        <v-btn-toggle v-model="filters.only_active" data-cy="block-filter-status" mandatory>
          <v-btn v-for="item in onlyActiveValues" :key="item.value" :value="item.value" text small>
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';

export default {
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      only_active: 'true',
    },
    onlyActiveValues: [
      {
        text: 'Active',
        value: 'true',
      },
      {
        text: 'All',
        value: null,
      },
    ],
  }),
};
</script>
