<template>
  <nv-tab-page>
    <template slot="toolbar">
      <diff-dialog
        :rule-eval-src-id="selectedIds[0]"
        :rule-eval-dst-id="selectedIds[1]"
        :btn-disabled="selectedIds.length !== 2"
        @close="diffDialogClose()"
      />
      <v-spacer />
      <rule-evaluations-filter @changed="setTableFilters" />
    </template>
    <v-container>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="table"
        :server-items-length="totalItems"
        :footer-props="footerProps"
        :items-per-page.sync="table.itemsPerPage"
        show-select
        class="elevation-1"
      >
        <template #item.created_at="{ item }">
          <span :title="item.created_at | time('long')">{{ item.created_at | time }}</span>
        </template>
        <template #item.status="{ item }">
          {{ item.status | humanize }}
        </template>
      </v-data-table>
    </v-container>
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/bee';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import DiffDialog from '@/components/v60_clients/DiffDialog.vue';
import RuleEvaluationsFilter from '@/components/v60_clients/RuleEvaluationsFilter';

const headers = [
  { text: 'Date Time', value: 'created_at' },
  { text: 'Status', sortable: false, value: 'status' },
];

export default {
  components: { RuleEvaluationsFilter, DiffDialog },
  mixins: [TableMixin],
  props: {
    v60: { type: String, default: null },
  },
  data: () => ({
    headers,
    selected: [],
    table: { sortBy: ['created_at'], sortDesc: [true] },
    tableParam: 'eval.dt',
    searchParam: 'eval.q',
  }),
  computed: {
    selectedIds() {
      return this.selected.map(el => el.id);
    },
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/v60_clients/${this.v60}/rule_evaluations`);
    },
    diffDialogClose() {
      this.selected = [];
    },
  },
};
</script>
