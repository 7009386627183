<template>
  <nv-tab-page>
    <template slot="toolbar">
      <v-row justify="space-between" align="center" dense>
        <v-col sm="4" cols="12">
          <new-map-form :v60="v60" @close="mapClose" />
          <status-changer-btn v-model="editMode" class="ml-2" />
        </v-col>
        <filter-sheet @changed="setTableFilters" />
        <v-col sm="5" cols="12">
          <nv-table-search ref="search" v-model="search" />
        </v-col>
      </v-row>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.pattern }}</td>
          <td>{{ item.match_subdomains | formatMatch }}</td>
          <td>{{ item.ddrs_entity && item.ddrs_entity.name }}</td>
          <td>{{ item.ddrs_entity && item.ddrs_entity.client_id }}</td>
          <td>{{ item.ddrs_entity && item.ddrs_entity.vc_id }}</td>
          <td>
            <status-changer
              v-model="item.status"
              :update-url="`/rules/${item.id}`"
              :edit-mode="editMode"
            />
          </td>
          <td class="text-right">
            <v-btn
              v-if="$auth.hasPermissions('bee.mappings')"
              icon
              data-cy="edit-button"
              @click="openEdit(item)"
            >
              <v-icon class="text-right">mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <edit-form v-model="form.show" :editing="form.edit" @saved="tableFetch" />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/bee';
import NewMapForm from '@/components/common/NewMapForm.vue';
import StatusChangerBtn from '@/components/StatusChangerBtn.vue';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import { formatMatch } from '@/helpers/filters';
import StatusChanger from '@/components/StatusChanger';
import FilterSheet from '@/components/rules/FilterSheet';
import EditForm from '@/components/rules/EditForm';

const headers = [
  { text: 'Rule', align: 'left', sortable: false, value: 'pattern' },
  { text: 'Match Subdomains', align: 'left', sortable: false, value: 'match_subdomains' },
  { text: 'Name', align: 'left', sortable: false, value: 'name' },
  { text: 'Client ID', align: 'left', sortable: false, value: 'ddrs' },
  { text: 'Sub-brand', align: 'left', sortable: false, value: 'c6' },
  { text: 'Status', align: 'left', sortable: false, value: 'status' },
  { text: ' ', align: 'right', sortable: false, value: 'action' },
];

export default {
  components: {
    EditForm,
    FilterSheet,
    NewMapForm,
    StatusChanger,
    StatusChangerBtn,
  },
  filters: { formatMatch },
  mixins: [TableMixin],
  props: { v60: { type: String, default: null } },
  data: () => ({
    headers,
    client: { type: null, id: null },
    editMode: false,
    tableParam: 'rules.dt',
    searchParam: 'rules.q',
    form: {
      show: false,
      edit: null,
    },
    table: { sortBy: ['pattern'], sortDesc: [false] },
  }),
  methods: {
    mapClose() {
      this.tableFetch();
    },
    tableFetch() {
      this.paginateTableData(HTTP, `/v60_clients/${this.v60}/rules`);
    },
    openEdit(item = null) {
      this.form.edit = item;
      this.form.show = true;
    },
  },
};
</script>
