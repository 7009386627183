<template>
  <v-dialog v-model="diffDialog" persistent max-width="1400px">
    <template #activator="{ on }">
      <v-btn
        ref="compare-btn"
        slot="toolbar"
        :disabled="btnDisabled"
        data-cy="btn-compare"
        color="secondary"
        v-on="on"
      >
        Compare
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Difference</span>
      </v-card-title>

      <v-card-text>
        <v-data-table :items="items" :headers="headers"> </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" @click="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/bee';

export default {
  props: {
    ruleEvalSrcId: { type: Number, default: null },
    ruleEvalDstId: { type: Number, default: null },
    btnDisabled: { type: Boolean, default: true },
  },
  data: () => ({
    diffDialog: false,
    items: [],
    headers: [
      { text: 'URL', value: 'url' },
      { text: 'Before', value: 'before' },
      { text: 'After', value: 'after' },
    ],
    buffRuleEvalSrcId: null,
    buffRuleEvalDstId: null,
  }),
  watch: {
    diffDialog() {
      if (this.diffDialog) {
        this.onShow();
      }
    },
  },
  created() {
    this.onShow();
  },
  methods: {
    onShow() {
      if (this.ruleEvalSrcId && this.ruleEvalDstId) {
        if (this.ruleEvalSrcId !== this.buffRuleEvalSrcId) {
          if (this.ruleEvalDstId !== this.buffRuleEvalDstId) {
            this.fetchData();
          }
        }
      }
    },
    fetchData() {
      this.loading = true;
      HTTP.get(`/rule_evaluations/${this.ruleEvalSrcId}/diff/${this.ruleEvalDstId}`)
        .then(res => {
          this.items = res.data.filter(x => x.differs === true);
          this.buffRuleEvalSrcId = this.ruleEvalSrcId;
          this.buffRuleEvalDstId = this.ruleEvalDstId;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.diffDialog = false;
      this.$emit('close', false);
    },
  },
};
</script>
