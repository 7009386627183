<template>
  <v-autocomplete
    ref="selector"
    :value="value"
    :items="items"
    :search-input.sync="search"
    :loading="loading"
    :rules="validations.presence"
    :disabled="disabled"
    label="V60"
    hint="V60"
    item-text="id"
    item-value="id"
    multi-line
    clearable
    @input="$emit('input', $event)"
  />
</template>

<script>
import HTTP from '@/api/bee';
import { debounce } from 'lodash';
import validations from '@/helpers/validations';

export function fetchV60Clients() {
  this.loading = true;
  HTTP.get('/v60_clients', { params: { search: this.search, per_page: 1000 } })
    .then(res => {
      this.items = res.data;
    })
    .finally(() => {
      this.loading = false;
    });
}

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    validations,
    items: [],
    search: '',
  }),
  watch: {
    search: debounce(fetchV60Clients, 500),
  },
};
</script>
