<template>
  <nv-tab-page>
    <template slot="toolbar">
      <v-text-field
        ref="search"
        v-model="search"
        :loading="loading"
        flat
        solo
        hide-details
        placeholder="URL"
        @keyup.enter="fetchData"
      />
      <v-btn ref="submit" color="secondary" class="ml-2" @click="fetchData">
        Search
      </v-btn>
    </template>
    <v-container>
      <v-card>
        <v-card-text>
          <v-row>
            <nv-show data-test="url-show" cols="12" sm="12" header="URL">
              {{ item.search }}
            </nv-show>
            <nv-show data-test="rule" cols="12" sm="12" header="Rule">
              {{ rule.pattern }}
            </nv-show>
            <nv-show data-test="client-id-show" cols="12" sm="6" header="Client ID">
              {{ item.cid }}
            </nv-show>
            <nv-show data-test="sub-brand-show" cols="12" sm="6" header="Sub-brand">
              {{ item.vc_id }}
            </nv-show>
            <nv-show data-test="ddrs-parent-show" cols="12" sm="6" header="Parent">
              {{ ddrsdata.parent }}
            </nv-show>
            <nv-show data-test="ddrs-name-show" cols="12" sm="6" header="Name">
              {{ ddrsdata.name }}
            </nv-show>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </nv-tab-page>
</template>

<script>
import RTL from '@/api/rtl';
import HTTP from '@/api/bee';

export default {
  props: { v60: { type: String, default: null } },
  data: () => ({
    search: '',
    dcr: '',
    item: { search: '', rule_id: '' },
    ddrsdata: { parent: null, name: null },
    mapDialog: false,
    client: { type: null, id: null },
    loading: false,
    rule: { pattern: '' },
  }),
  methods: {
    fetchData() {
      this.loading = true;
      RTL.get('/clients/match', {
        params: { client_id: this.v60, url: this.search },
      })
        .then(res => {
          if (res.data && res.data.status === 'ok') {
            this.item = res.data.client;
            this.item.rule_id = res.data.rule.id;
            this.fetchMatchedRule();
            this.fetchDDRSEntity();
          } else {
            this.item = { search: '', rule_id: '' };
            this.rule = { pattern: '' };
            this.ddrsdata = { parent: null, name: null };
          }
          this.item.search = this.search;
        })
        .catch(err => {
          // Catch 502 error
          if (err.response && err.response.status === 502) {
            this.item = {};
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchMatchedRule() {
      if (!this.item.rule_id) {
        return;
      }
      this.loading = true;
      HTTP.get(`/rules/${this.item.rule_id}`)
        .then(res => {
          this.rule = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchDDRSEntity() {
      this.loading = true;
      HTTP.get(`/ddrs_entities/${this.item.id}`)
        .then(res => {
          this.ddrsdata = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
