<template>
  <div>
    <kbd>{{ before }}</kbd>
    &rarr;
    <kbd>{{ after }}</kbd>
  </div>
</template>

<script>
export default {
  props: {
    before: { type: String, default: '' },
    after: { type: String, default: '' },
  },
};
</script>
