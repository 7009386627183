<template>
  <v-dialog :value="value" persistent max-width="500px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :loading="loading"
      :title="formTitle"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="v60"
            v-model="item.id"
            :rules="rules.id"
            :disabled="!!editing"
            label="V60 ID"
            hint="V60 Client ID"
            counter="30"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-select
            ref="status"
            v-model="item.status"
            :rules="rules.status"
            :items="enumStatuses"
            label="Status"
            required
            multi-line
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/bee';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import { rules } from './validations';

export default {
  mixins: [FormMixin],
  data: () => ({
    rules,
    enumStatuses: [],
    formContext: 'V60 Client',
  }),
  created() {
    this.enumFetch(HTTP, {
      enumStatuses: '/v60_clients/enum/statuses',
    });
  },
  methods: {
    enumFetch,
    formSave() {
      if (this.editing) {
        return this.submitForm(HTTP, '/v60_clients', {
          success: () => {
            this.$flash('success', `${this.formContext} ${this.formSuccessMsg}!`);
          },
        });
      } else {
        return HTTP.post('/v60_clients', this.item, { niSkipFlash: true })
          .then(() => {
            this.$flash('success', `${this.formContext} ${this.formSuccessMsg}!`);
          })
          .catch(err => {
            this.formErrorHandler(err);
          });
      }
    },
  },
};
</script>
