<template>
  <v-container>
    <v-row dense justify="center">
      <v-col class="text-center" cols="12">
        <span class="text-caption mr-1">Status:</span>&nbsp;
        <v-btn-toggle v-model="filters.status" data-cy="block-filter-status">
          <v-btn v-for="item in enumStatuses" :key="item.value" :value="item.value" text small>
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HTTP from '@/api/bee';
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';

export default {
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'active',
    },
    enumStatuses: [],
    enumFetch,
  }),
  created() {
    this.enumFetch(HTTP, {
      enumStatuses: '/v60_clients/enum/statuses',
    });
  },
};
</script>
