<template>
  <v-col class="pr-6 text-left text-sm-right" sm="3" cols="12">
    <span class="text-caption mr-1">Status:</span>&nbsp;
    <v-btn-toggle v-model="filters.status">
      <v-btn v-for="item in statuses" :key="item.value" :value="item.value" small text>
        {{ item.text }}
      </v-btn>
    </v-btn-toggle>
  </v-col>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';

export default {
  name: 'FilterSheet',
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'active',
      archived: 'false',
    },
    statuses: [
      {
        text: 'Active',
        value: 'active',
      },
      {
        text: 'Inactive',
        value: 'inactive',
      },
    ],
    filtersParam: 'rules.ft',
  }),
};
</script>
