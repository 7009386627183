<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :options.sync="table"
    :server-items-length="totalItems"
    :footer-props="footerProps"
    :items-per-page.sync="table.itemsPerPage"
    must-sort
  >
    <template #item="{ item }">
      <tr>
        <td :title="item.created_at | time('long')">
          {{ item.created_at | time('long') }}
        </td>
        <td>{{ item.event | humanize }}</td>
        <td v-placeholder>{{ item.user }}</td>
        <td v-placeholder>{{ item.context.type | humanize }}</td>
        <td v-placeholder>{{ item.context.pattern }}</td>
        <td>
          <ul class="py-4">
            <li v-for="(change, index) in item.changes" :key="index">
              <span class="font-weight-bold">{{ change.attribute | humanize }}:</span>
              &nbsp;
              <changelog-item
                :before="JSON.stringify(change.before)"
                :after="JSON.stringify(change.after)"
              />
            </li>
          </ul>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import HTTP from '@/api/bee';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import ChangelogItem from '@/components/ChangelogItem.vue';

const headers = [
  { text: 'Timestamp', align: 'left', sortable: false, value: 'created_at' },
  { text: 'Event', align: 'left', sortable: false, value: 'event' },
  { text: 'User', align: 'left', sortable: false, value: 'user' },
  { text: 'Context', align: 'left', sortable: false, value: 'context' },
  { text: 'Pattern', align: 'left', sortable: false, value: 'pattern' },
  { text: 'Changes', align: 'left', sortable: false, value: 'changes' },
];

export default {
  components: { ChangelogItem },
  mixins: [TableMixin],
  props: { v60: { type: String, required: true } },
  data: () => ({
    headers,
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/v60_clients/${this.v60}/history`);
    },
  },
};
</script>
