import Vue from 'vue';
import Router from 'vue-router';
import { withAuth } from '@nielsen-media/ui-toolkit-vue-api';

import V60ClientsList from './views/v60_clients/List.vue';
import V60ClientsShow from './views/v60_clients/Show.vue';
import DDRSEntitiesList from './views/ddrs_entities/List.vue';
import DDRSEntitiesShow from './views/ddrs_entities/Show.vue';
import RulesList from './views/rules/List.vue';
import UnknownV60Clients from './views/unknown_v60_clients/List.vue';

Vue.use(Router);

export default withAuth(
  new Router({
    mode: 'history',
    routes: [
      {
        path: '/v60_clients',
        component: V60ClientsList,
        name: 'v60_clients',
      },
      {
        path: '/',
        redirect: '/v60_clients',
      },
      {
        path: '/v60_clients/:v60ID',
        component: V60ClientsShow,
        name: 'v60_clients.show',
      },
      {
        path: '/ddrs_entities',
        component: DDRSEntitiesList,
        name: 'ddrs_entities',
      },
      {
        path: '/ddrs_entities/:ddrsID',
        component: DDRSEntitiesShow,
        name: 'ddrs_entities.show',
      },
      {
        path: '/rules',
        component: RulesList,
        name: 'rules',
      },
      {
        path: '/unknown_v60_clients',
        component: UnknownV60Clients,
        name: 'unknown_v60_clients',
      },
      { path: '*', redirect: '/v60_clients' },
    ],
  }),
);
