import vrb from 'bsm-validation-rules';

export const statuses = ['inactive', 'active'];

export const rules = {
  pattern: [
    vrb.presence(),
    vrb.typeOf('string'),
    vrb.length({ max: 255 }),
    vrb.format(/^(?!https?:\/\/)/, 'must not start with protocol'),
    vrb.format(/^[A-Za-z0-9*?][\w\-.*?\\~:%/&#=]+$/, 'must have valid URL format'),
    vrb.format(/^[^A-Z]+$/, 'must be lowercase'),
  ],
  matchSubdomains: [vrb.presence(), vrb.typeOf('boolean')],
  status: [vrb.presence(), vrb.typeOf('string'), vrb.inclusion(statuses)],
};
