<template>
  <v-dialog :value="value" persistent max-width="800px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :loading="loading"
      :title="formTitle"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="rule"
            v-model="item.pattern"
            :rules="rules.pattern"
            label="Rule"
            hint="The rule pattern eg. nielsen.com/foo/*."
            counter="255"
            required
          />
        </v-col>

        <v-col cols="12">
          <ddrs-entity-autocomplete v-model="item.ddrs_entity_id" />
        </v-col>

        <v-col cols="12" sm="4">
          <v-switch
            ref="match-subdomains"
            v-model="item.match_subdomains"
            :rules="rules.matchSubdomains"
            label="Match Subdomains"
            color="accent"
            required
            multi-line
          />
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="4">
          <v-select
            ref="status"
            v-model="item.status"
            :rules="rules.status"
            :items="statuses"
            label="Status"
            required
            multi-line
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/bee';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import DdrsEntityAutocomplete from '@/components/ddrs_entities/Autocomplete.vue';
import { rules, statuses } from './validations';

export default {
  components: { DdrsEntityAutocomplete },
  mixins: [FormMixin],
  data: () => ({
    rules,
    statuses,
    formContext: 'Rule',
  }),
  methods: {
    formReady() {
      this.item.ddrs_entity_id = this.editing?.ddrs_entity?.id;
    },
    formSave() {
      return this.submitForm(HTTP, `/rules`, {
        success: () => {
          this.$flash('success', `${this.formContext} ${this.formSuccessMsg}!`);
        },
      });
    },
  },
};
</script>
