<template>
  <nv-tab-page>
    <template slot="toolbar">
      <v-row justify="space-between" align="center" no-gutters>
        <v-col sm="5" cols="12">
          <new-map-form :ddrs="ddrs" :default-v60-client="defaultV60Client" @close="mapClose()" />
          <v-btn
            v-if="$auth.hasPermissions('bee.clients')"
            color="secondary"
            data-cy="disable-all-rules-button"
            class="mx-2"
            @click="disableAction"
          >
            Disable all
          </v-btn>
          <status-changer-btn v-model="editMode" />
        </v-col>
        <filter-sheet @changed="setTableFilters" />
        <v-col sm="4" cols="12">
          <nv-table-search ref="search" v-model="search" />
        </v-col>
      </v-row>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.pattern }}</td>
          <td>{{ item.match_subdomains | formatMatch }}</td>
          <td>{{ item.ddrs_entity && item.ddrs_entity.name }}</td>
          <td>{{ item.v60_client && item.v60_client.id }}</td>
          <td>
            <status-changer
              v-model="item.status"
              :update-url="`/rules/${item.id}`"
              :edit-mode="editMode"
            />
          </td>
          <td class="text-right">
            <v-btn
              v-if="$auth.hasPermissions('bee.mappings')"
              icon
              data-cy="edit-button"
              @click="openEdit(item)"
            >
              <v-icon class="text-right">mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <edit-form v-model="form.show" :editing="form.edit" @saved="tableFetch" />

    <nv-confirm ref="confirm" />
  </nv-tab-page>
</template>

<script>
import NewMapForm from '@/components/common/NewMapForm.vue';
import StatusChangerBtn from '@/components/StatusChangerBtn.vue';
import HTTP from '@/api/bee';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import StatusChanger from '@/components/StatusChanger';
import { formatMatch } from '@/helpers/filters';
import FilterSheet from '@/components/rules/FilterSheet';
import EditForm from '@/components/rules/EditForm';

const headers = [
  { text: 'Rule', align: 'left', sortable: false, value: 'rule' },
  { text: 'Match Subdomains', align: 'left', sortable: false, value: 'match_subdomains' },
  { text: 'Name', align: 'left', sortable: false, value: 'name' },
  { text: 'V60', align: 'left', sortable: false, value: 'v60' },
  { text: 'Status', align: 'left', sortable: false, value: 'status' },
  { text: ' ', align: 'right', sortable: false, value: 'action' },
];

export default {
  components: {
    EditForm,
    FilterSheet,
    StatusChanger,
    NewMapForm,
    StatusChangerBtn,
  },
  filters: { formatMatch },
  mixins: [TableMixin],
  props: {
    ddrs: { type: [String, Number], default: null },
    defaultV60Client: { type: String, default: null },
  },
  data: () => ({
    headers,
    tableParam: 'rules.dt',
    searchParam: 'rules.q',
    form: { show: false, edit: null },
    editMode: false,
  }),
  methods: {
    mapClose() {
      this.tableFetch();
    },
    disableClose() {
      this.disableDialog = false;
    },
    tableFetch() {
      this.paginateTableData(HTTP, `/ddrs_entities/${this.ddrs}/rules`);
    },
    disableAction() {
      this.$refs.confirm.open('Disable all rules').then(() => {
        HTTP.put(`/ddrs_entities/${this.ddrs}/deactivate`, {}).then(() => {
          this.$flash('success', 'Rules disabled!');
          this.tableFetch();
        });
      });
    },
    openEdit(item = null) {
      this.form.edit = item;
      this.form.show = true;
    },
  },
};
</script>
