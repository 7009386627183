<template>
  <nv-page :header="item.parent + ' / ' + item.name">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
    </template>
    <v-card class="pa-4">
      <v-row no-gutters>
        <nv-show ref="parent" cols="6" sm="3" header="Parent">{{ item.parent }}</nv-show>
        <nv-show ref="name" cols="6" sm="3" header="Name">{{ item.name }}</nv-show>
        <nv-show ref="client_id" cols="6" sm="2" header="Client ID">{{ item.client_id }}</nv-show>
        <nv-show ref="vc_id" cols="6" sm="2" header="Sub-brand">{{ item.vc_id }}</nv-show>
        <nv-show ref="status" cols="6" sm="2" header="Status">{{ item.status | humanize }}</nv-show>
      </v-row>
    </v-card>
    <v-card class="mt-4 elevation-2">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab data-cy="rules">Rules</v-tab>
      </v-tabs>
      <v-tabs-items v-if="item.id" v-model="tabs">
        <v-tab-item>
          <rules-tab v-if="item.id" :ddrs="item.id" :default-v60-client="item.default_v60_client" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </nv-page>
</template>

<script>
import HTTP from '@/api/bee';
import RulesTab from '@/components/ddrs_entities/RulesTab.vue';

export default {
  components: { RulesTab },
  data: () => ({
    item: {},
    tabs: 0,
  }),
  created() {
    HTTP.get(`/ddrs_entities/${this.$route.params.ddrsID}`).then(res => {
      this.item = res.data;
    });
  },
};
</script>
