<template>
  <nv-page :header="item.id">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
      <nv-btn
        v-if="$auth.hasPermissions('bee.clients')"
        type="edit"
        data-cy="edit-v60-client-btn"
        @click="editDialog.show = true"
      />
      <edit-form v-if="item.id" v-model="editDialog.show" :editing="item" @saved="fetchData" />
    </template>
    <v-card class="pa-4">
      <v-row dense>
        <nv-show ref="name" cols="12" sm="6" header="Name">{{ item.id }}</nv-show>
        <nv-show ref="status" cols="12" sm="6" header="Status">
          {{ item.status | humanize }}
        </nv-show>
      </v-row>
    </v-card>
    <v-card class="mt-4 elevation-2">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab data-cy="rules-tab">Rules</v-tab>
        <v-tab data-cy="mapping-search-tab">Mapping Search</v-tab>
        <v-tab data-cy="unmapped-tab">Unmapped URLs</v-tab>
        <v-tab data-cy="rule-evaluations-tab">Rule Evaluations</v-tab>
        <v-tab data-cy="history-tab">History</v-tab>
      </v-tabs>
      <v-tabs-items v-if="item.id" v-model="tabs">
        <v-tab-item>
          <rules-tab ref="rules" :v60="item.id" />
        </v-tab-item>
        <v-tab-item>
          <mapping-search-tab :v60="item.id" />
        </v-tab-item>
        <v-tab-item>
          <unmapped-urls-tab :v60="item.id" />
        </v-tab-item>
        <v-tab-item>
          <rule-evaluations-tab :v60="item.id" />
        </v-tab-item>
        <v-tab-item>
          <history-tab v-if="tabs === 4" :v60="item.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </nv-page>
</template>

<script>
import HTTP from '@/api/bee';
import RulesTab from '@/components/v60_clients/RulesTab.vue';
import MappingSearchTab from '@/components/v60_clients/MappingSearchTab.vue';
import UnmappedUrlsTab from '@/components/v60_clients/UnmappedUrlsTab.vue';
import RuleEvaluationsTab from '@/components/v60_clients/RuleEvaluationsTab.vue';
import HistoryTab from '@/components/v60_clients/HistoryTab.vue';
import EditForm from '@/components/v60_clients/EditForm.vue';

export default {
  components: {
    RulesTab,
    MappingSearchTab,
    UnmappedUrlsTab,
    RuleEvaluationsTab,
    HistoryTab,
    EditForm,
  },
  data: () => ({
    item: {},
    editDialog: { show: false },
    tabs: 0,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/v60_clients/${this.$route.params.v60ID}`).then(res => {
        this.item = res.data;
      });
    },
  },
};
</script>
